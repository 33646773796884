<template>
  <div class="career_category">
    <b-button @click="modalShow = !modalShow">{{
      $t("add_career_category.add_career_category")
    }}</b-button>
    <b-modal id="modal-xl" v-model="modalShow" size="xl" hide-footer>
      <form @submit.prevent="addCategory">
        <div>
          <label for=""> {{ $t("admin.title") }} (KA)</label>
          <b-form-input v-model="form.titleKA"></b-form-input>
        </div>
        <div>
          <label for=""> {{ $t("admin.title") }} (EN)</label>
          <b-form-input v-model="form.titleEN"></b-form-input>
        </div>
        <div>
          <label for="">
            {{ $t("add_career_category.category") }}
          </label>
          <b-form-select
            v-model="form.category"
            :options="Categories"
          ></b-form-select>
        </div>
        <div>
          <label for="">{{ $t("admin.activate_on_front") }}</label>
        </div>
        <input class="checkbox" type="checkbox" v-model="form.show" />
        <button type="submit" class="submit btn btn-primary">
          <span v-show="!isLoading">{{ $t("admin.add") }}</span>
          <span v-show="isLoading">
            {{ $t("login.loading") }}
            <spinner v-show="isLoading" text="loading" />
          </span>
        </button>
      </form>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import env from "../../../env.json";
import spinner from "../../spinner/Spinner.vue";
import Swal from "sweetalert2";

export default {
  components: {
    spinner,
  },
  name: "login",
  data() {
    return {
      modalShow: false,
      form: {
        show: false,
        titleKA: "",
        titleEN: "",
        category: null,
      },
      Categories: [
        {
          value: null,
          text: this.$t("add_career_category.choose_career_category"),
        },
        { value: "category", text: "category" },
        { value: "location", text: "location" },
      ],
      isLoading: false,
    };
  },
  props: {
    getCareerCategory: { type: Function },
  },
  methods: {
    addCategory() {
      this.isLoading = true;
      axios
        .post(`${env.host}/add/career/categories`, this.form)
        .then((res) => {
          Swal.fire({
            icon: "success",
            text: this.$t("success.success_add"),
          });
          this.isLoading = false;
          this.modalShow = false;
          this.emptyValues();
          setTimeout(() => {
            this.getCareerCategory();
          }, 1000);
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
    emptyValues() {
      this.form.show = false;
      this.form.titleKA = "";
      this.form.titleEN = "";
      this.form.category = null;
    },
  },
};
</script>

<style scoped>
label {
  font-family: mtavruliBOLD;
}
.checkbox {
  margin-bottom: 20px;
  margin-left: 10px;
}
input[type="checkbox"] {
  -ms-transform: scale(2); /* IE */
  -moz-transform: scale(2); /* FF */
  -webkit-transform: scale(2); /* Safari and Chrome */
  -o-transform: scale(2); /* Opera */
  transform: scale(2);
  padding: 10px;
}
button {
  background: #7e5493;
  border: none;
  padding: 10px 30px 10px 30px;
  border-radius: 0px;
}
.career_category {
  margin-bottom: 10px;
}
.submit {
  width: 100%;
  height: 45px;
  border: none;
  background: #7e5493;
  border-radius: 0px;
  cursor: pointer;
  outline: none;
  color: white;
}
::v-deep .modal-body form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
</style>
