<template>
  <div
    class="career_category_management"
    style="overflow: hidden; padding: 5px 10px 10px 10px"
  >
    <AddCareerCategory :getCareerCategory="getCareerCategory" />

    <div v-if="!isLoading" class="testingScroll">
      <table>
        <tr>
          <th>{{ $t("admin.title") }}</th>
        </tr>
        <tr v-for="items in allCareerCategory" :key="items.id">
          <td class="mainBoxCategory">
            <div v-for="item in items" :key="item.id">
              <td class="show_on_front">
                <i v-if="item.show == true" class="fal fa-check-circle"></i>
                <i v-if="item.show == false" class="fal fa-ban"></i>
              </td>
              <td>
                <span v-if="lang=='ka'">{{ item.titleKA }}</span>
                <span v-else>{{ item.titleEN }}</span>
              </td>
              <td>
                {{ item.category }}
              </td>
              <td>
                <button
                  @click="
                    (modalShow = !modalShow),
                      editCareerCategory(
                        item._id,
                        item.show,
                        item.titleKA,
                        item.titleEN,
                        item.category
                      )
                  "
                >
                  <i class="edit fal fa-pen"></i>
                </button>
              </td>
              <td>
                <ConfirmDelete
                  @clicktodelete="deleteCareerCategory(item._id)"
                />
              </td>
            </div>
          </td>
        </tr>
      </table>
    </div>
    <div v-else>
      <b-skeleton-table
        :rows="3"
        :columns="4"
        :table-props="{ bordered: true, striped: true }"
      ></b-skeleton-table>
    </div>

    <b-modal id="modal-xl-2" v-model="modalShow" size="xl" hide-footer>
      <div class="input">
        <label for=""> {{ $t("admin.title") }} (KA)</label>
        <b-form-input v-model="update.titleKA"></b-form-input>
      </div>
      <div class="input">
        <label for=""> {{ $t("admin.title") }} (EN)</label>
        <b-form-input v-model="update.titleEN"></b-form-input>
      </div>
      <div class="input">
        <label for=""> {{ $t("add_career_category.category") }} </label>
        <b-form-select
          v-model="update.category"
          :options="Categories"
        ></b-form-select>
      </div>
      <div>
        <label for="">{{ $t("admin.activate_on_front") }}</label>
      </div>
      <input class="checkbox" type="checkbox" v-model="update.show" />
      <button
        type="submit"
        class="submit btn btn-primary"
        @click="UpdateCareerCategory()"
      >
        <span v-show="!isLoading">{{ $t("admin.edit") }}</span>
        <span v-show="isLoading">
          {{ $t("login.loading") }}
          <spinner v-show="isLoading" text="loading" />
        </span>
      </button>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import env from "../../../env.json";
import spinner from "../../spinner/Spinner.vue";
import ConfirmDelete from "../../../components/Confirm/DeleteConfirm.vue";
import Swal from "sweetalert2";

import AddCareerCategory from "../AddComponents/AddCareerCategory.vue";
export default {
  name: "getTeam",
  components: {
    AddCareerCategory,
    spinner,
    ConfirmDelete,
  },
  data() {
    return {
      allCareerCategory: [],
      checkedItems: [],
      active_id: "",
      update: {
        show: false,
        titleKA: "",
        titleEN: "",
        category: null,
      },
      Categories: [
        { value: "category", text: "category" },
        { value: "location", text: "location" },
      ],
      isLoading: false,
      modalShow: false,
      lang: "ka",
    };
  },
  mounted() {
    this.getCareerCategory();
    if (localStorage.getItem("lang") == "ka") {
      this.lang = "ka";
    } else {
      this.lang = "en";
    }
  },
  methods: {
    getCareerCategory() {
      this.isLoading = true;
      axios.get(`${env.host}/get/all/career/categories/admin`).then((res) => {
        this.isLoading = false;
        this.allCareerCategory = res.data.item;
      });
    },
    editCareerCategory(id, show, titleKA, titleEN, category) {
      this.active_id = id;
      this.update.show = show;
      this.update.titleKA = titleKA;
      this.update.titleEN = titleEN;
      this.update.category = category;
    },
    UpdateCareerCategory() {
      this.isLoading = true;
      axios
        .post(
          `${env.host}/edit/career/categories/${this.active_id}`,
          this.update
        )
        .then(() => {
          Swal.fire({
            icon: "success",
            text: this.$t("success.success_edit"),
          });
          this.isLoading = false;
          this.modalShow = false;
          setTimeout(() => {
            this.getCareerCategory();
          }, 1000);
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
    deleteCareerCategory(id) {
      this.isLoading = true;
      axios.delete(`${env.host}/delete/career/categories/${id}`).then(() => {
        Swal.fire({
          icon: "success",
          text: this.$t("success.success_delete"),
        });
        this.isLoading = false;
        this.getCareerCategory();
      });
    },
  },
};
</script>

<style scoped>
.mainBoxCategory {
  padding: 0px;
}
.testingScroll {
  width: 100% !important;
  min-height: calc(100vh - 240px);

  max-height: calc(100vh - 200px) !important;
  overflow-y: scroll;
}
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #7e549374;
  width: 7px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5e168274;
  cursor: pointer;
}
.career_category_management {
  width: 100%;
}
label {
  font-family: mtavruliBOLD;
}
.show_on_front {
  font-size: 30px;
  padding: 0px;
  padding-left: 10px;
}
input[type="checkbox"] {
  -ms-transform: scale(2); /* IE */
  -moz-transform: scale(2); /* FF */
  -webkit-transform: scale(2); /* Safari and Chrome */
  -o-transform: scale(2); /* Opera */
  transform: scale(2);
  padding: 10px;
}
.checkbox {
  margin-bottom: 20px;
  margin-left: 10px;
}
button {
  background: #7e5493;
  border: none;
  padding: 10px 30px 10px 30px;
  border-radius: 0px;
}
.delete {
  background: #f02849;
  border-radius: 0px;
}
.delete i,
.edit {
  color: white;
}
table {
  width: 100%;
  border-spacing: 0;
  border-radius: 0px;
  overflow: hidden;
}
thead {
  visibility: hidden;
  position: absolute;
  width: 0;
  height: 0;
}
th {
  background: #7e5493;
  color: #fff;
}

td:nth-child(1) {
  background: #7e5493;
  color: #fff;
  border-radius: 0px;
}
th,
td {
  padding: 1em;
}
tr,
td {
  display: block;
}
td {
  position: relative;
}
td::before {
  content: attr(data-label);
  position: absolute;
  left: 0;
  padding-left: 1em;
  font-weight: 600;
  font-size: 0.9em;
  text-transform: uppercase;
}
tr {
  margin-bottom: 1.5em;
  border: 1px solid #ddd;
  border-radius: 0px;
  text-align: right;
}
tr:last-of-type {
  margin-bottom: 0;
}
td:nth-child(n + 2):nth-child(odd) {
  background-color: #ddd;
}
@media only screen and (min-width: 768px) {
  thead {
    visibility: visible;
    position: relative;
  }
  th {
    text-align: left;
    text-transform: uppercase;
    font-size: 0.9em;
  }
  tr {
    display: table-row;
    border: none;
    border-radius: 0px;
    text-align: left;
  }
  tr:nth-child(even) {
    background-color: #ddd;
  }
  td {
    display: table-cell;
  }
  td::before {
    content: none;
  }
  td:nth-child(1) {
    background: transparent;
    color: #444;
    border-radius: 0px;
  }
  td:nth-child(n + 2):nth-child(odd) {
    background-color: transparent;
  }
}
.input {
  padding-bottom: 20px;
}
::v-deep .btn-secondary {
  background: #7e5493;
  border: none;
  padding: 10px 30px 10px 30px;
  border-radius: 0px;
}
.submit {
  width: 100%;
  height: 45px;
  border: none;
  background: #7e5493;
  border-radius: 0px;
  cursor: pointer;
  outline: none;
  color: white;
}
</style>
